import './App.css';

function App() {
  return (
    <div style={{paddingTop:'14rem'}}>
      <h1 style={{ textAlign: 'center' }}>XQUTUS</h1>
      <p style={{ textAlign: 'center' }}>Coming soon.....</p>

    </div>
  );
}

export default App;
